import React from "react";
import Helmet from "react-helmet";
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import axios from "axios";

class FacultyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faculty: "",
    };
  }
  async componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    const Faculty = await axios.get(
      "https://admin-panel-new.onrender.com/faculty/" +
        this.props.match.params.id
    );
    this.setState({ faculty: Faculty.data[0] });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            {this.state.faculty.name +
              " - Indian Institute of Information Technology, Kota"}
          </title>
          <meta
            name="description"
            content={
              this.state.faculty.name +
              " - Indian Institute of Information Technology, Kota"
            }
          />
          <meta
            name="keywords"
            content={
              this.state.faculty.name +
              " IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
            }
          />
        </Helmet>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>

            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 0 0 0" />
              </svg>
            </div>
          </section>
          <section className="section text-justify">
            <Container>

              {this.state.faculty.name == "Dr. Amit Kumar"?
              
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <img
                          alt="..."
                          className="rounded-circle"
                          height="190px"
                          src={this.state.faculty.photourl}
                        />
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      {/* <div className="card-profile-actions py-4 mt-lg-0 row">
                        <Button
                          className="ml-4"
                          color="info"
                          href={"mailto:" + this.state.faculty.mail}
                          onClick={(e) => e.preventDefault()}
                          size="small"
                        >
                          <i className="fa fa-envelope"></i>
                        </Button> */}
                      {/* {this.state.faculty.linkedin && (
                          <Button
                            color="default"
                            href={this.state.faculty.linkedin}
                            onClick={(e) => e.preventDefault()}
                            size="small"
                          >
                            <i className="fab fa-linkedin"></i>
                          </Button>
                        )}
                        {this.state.faculty.facebook && (
                          <Button
                            color="default"
                            href={this.state.faculty.facebook}
                            onClick={(e) => e.preventDefault()}
                            size="small"
                          >
                            <i className="fab fa-facebook"></i>
                          </Button>
                        )}
                        {this.state.faculty.twitter && (
                          <Button
                            color="default"
                            href={this.state.faculty.twitter}
                            onClick={(e) => e.preventDefault()}
                            size="small"
                          >
                            <i className="fab fa-twitter"></i>
                          </Button>
                        )} */}
                      {/* </div> */}
                    </Col>
                    <Col className="order-lg-1" lg="4"></Col>
                  </Row>
                  <div
                    className="text-center mt-8"
                    style={{ paddingTop: "10px" }}
                  >
                    <h3>{this.state.faculty.name}</h3>
                    {/* <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.state.faculty.role} Faculty
                    </div> */}
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {/*
                      <a href={"tel:" + this.state.faculty.phonenumber}>
                        {this.state.faculty.phonenumber}
                      </a>{" "}
                      */}
                      <br />
                      {this.state.faculty.mail}
                      <br />
                      {this.state.faculty.description}
                      <br />
                      {this.state.faculty.department}
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Indian Institute of Information Technology, Kota
                    </div>
                    <div>
                      <a href={this.state.faculty.gspl} target="_blank" rel="noopener noreferrer">
                        Scholar Profile{" "}
                      </a>
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top">
                    <Row className="justify-content-center text-justify">
                      <Col lg="9">
                        <h5>
                          <b>Office Address</b>
                        </h5>
                        <h6>
                          Indian Institute of Information Technology, Kota{" "}
                          <br />
                          SPL-269, RIICO Industrial Area, Kuber Extension, Ranpur, 
                          {/* 2nd Floor, East Wing, Prabha Bhawan, MNIT Jaipur
                          Campus */}
                          <br /> Kota – 325003, (Rajasthan, India)
                        </h6>
                      </Col>
                      {this.state.faculty.qualification &&
                        this.state.faculty.qualification.length > 0 && (
                          <Col lg={9} className="mt-3">
                            <h5>
                              <b>Qualification</b>
                            </h5>

                            <h6>
                              <ul>
                                {this.state.faculty.qualification.map(
                                  (qual) => {
                                    return <li>{qual}</li>;
                                  }
                                )}
                              </ul>
                            </h6>
                          </Col>
                        )}
                      {this.state.faculty.tnrexperience &&
                        this.state.faculty.tnrexperience.length > 0 && (
                          <Col lg={9} className="mt-3">
                            <h5>
                              <b>Teaching & Research Experience</b>
                            </h5>

                            <h6>
                              <ul>
                                {this.state.faculty.tnrexperience.map(
                                  (trnr) => {
                                    return <li>{trnr}</li>;
                                  }
                                )}
                              </ul>
                            </h6>
                          </Col>
                        )}

                      {this.state.faculty.research_intersts &&
                        this.state.faculty.research_intersts.length > 0 && (
                          <Col lg={9} className="mt-3">
                            <h5>
                              <b>Research Interests</b>
                            </h5>

                            <h6>
                              <ul>
                                {this.state.faculty.research_intersts.map(
                                  (ri) => {
                                    return <li>{ri}</li>;
                                  }
                                )}
                              </ul>
                            </h6>
                          </Col>
                        )}
                      {this.state.faculty.rolesnresp &&
                        this.state.faculty.rolesnresp.length > 0 && (
                          <Col lg={9} className="mt-3">
                            {this.state.faculty.rolesnresp &&
                              this.state.faculty.rolesnresp.length > 0 && (
                                <h5>
                                  <b>Roles and Responsibilities</b>
                                </h5>
                              )}
                            <h6>
                              <ul>
                                {this.state.faculty.rolesnresp &&
                                  this.state.faculty.rolesnresp.map((ri) => {
                                    return <li>{ri}</li>;
                                  })}
                              </ul>
                            </h6>
                          </Col>
                        )}

                      {this.state.faculty.fundedprojects &&
                        this.state.faculty.fundedprojects.length > 0 && (
                          <Col lg={9} className="mt-3">
                            <h5>
                              <b>Funded Projects</b>
                            </h5>
                            <h6>
                              <ol>
                                {this.state.faculty.fundedprojects.map((p) => {
                                  return (
                                    <li>
                                      &nbsp;{p[0]}
                                      <ul>
                                        {/* <li>{p[0]}</li> */}
                                        <li>
                                          <b>Role:</b> {p[1]}
                                        </li>
                                        <li>
                                          <b>Amount:</b> {p[2]}
                                        </li>
                                        <li>
                                          <b>Funded By:</b> {p[3]}
                                        </li>
                                        <li>
                                          <b>Status:</b> {p[4]}
                                        </li>
                                      </ul>
                                      <br />
                                    </li>
                                  );
                                })}
                              </ol>
                            </h6>
                          </Col>
                        )}

                      {this.state.faculty.outreach_activities &&
                        this.state.faculty.outreach_activities.length > 0 && (
                          <Col lg={9} className="mt-3">
                            <h5>
                              <b>Outreach Activities</b>
                            </h5>
                            <h6>
                              <ul>
                                {this.state.faculty.outreach_activities.map(
                                  (ri) => {
                                    return <li>{ri}</li>;
                                  }
                                )}
                              </ul>
                            </h6>
                          </Col>
                        )}

                      {this.state.faculty.other &&
                        this.state.faculty.other.length > 0 && (
                          <Col lg={9} className="mt-3">
                            <h5>
                              <b>Other Notable Information</b>
                            </h5>
                            <h6>
                              <ul>
                                {this.state.faculty.other.map((ri) => {
                                  return <li>{ri}</li>;
                                })}
                              </ul>
                            </h6>
                          </Col>
                        )}

                      <Col lg={9} className="mt-3">
                        {(this.state.faculty.journals ||
                          this.state.faculty.conferences) && (
                          <h5>
                            <b>Publications</b>
                          </h5>
                        )}
                        <h6>
                          {this.state.faculty.journals && <b>Journals</b>}
                          <ol className="mt-2">
                            {this.state.faculty.journals &&
                              this.state.faculty.journals.map((ri) => {
                                return <li>{ri}</li>;
                              })}
                          </ol>
                        </h6>
                        <h6>
                          {this.state.faculty.bookchapters && (
                            <b>Book Chapters</b>
                          )}
                          <ol className="mt-2">
                            {this.state.faculty.bookchapters &&
                              this.state.faculty.bookchapters.map((ri) => {
                                return <li>{ri}</li>;
                              })}
                          </ol>
                        </h6>
                        <h6>
                          {this.state.faculty.books && <b>Books</b>}
                          <ol className="mt-2">
                            {this.state.faculty.books &&
                              this.state.faculty.books.map((ri) => {
                                return <li>{ri}</li>;
                              })}
                          </ol>
                        </h6>
                        <h6>
                          {this.state.faculty.conferences && <b>Conferences</b>}
                          <ol className="mt-2">
                            {this.state.faculty.conferences &&
                              this.state.faculty.conferences.map((ri) => {
                                return <li>{ri}</li>;
                              })}
                          </ol>
                        </h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>

                            :
                    
                            <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                              <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                  <div className="card-profile-image">
                                    <img
                                      alt="..."
                                      className="rounded-circle"
                                      height="190px"
                                      src={this.state.faculty.photourl}
                                    />
                                  </div>
                                </Col>
                                <Col
                                  className="order-lg-3 text-lg-right align-self-lg-center"
                                  lg="4"
                                >
                                  {/* <div className="card-profile-actions py-4 mt-lg-0 row">
                                    <Button
                                      className="ml-4"
                                      color="info"
                                      href={"mailto:" + this.state.faculty.mail}
                                      onClick={(e) => e.preventDefault()}
                                      size="small"
                                    >
                                      <i className="fa fa-envelope"></i>
                                    </Button> */}
                                  {/* {this.state.faculty.linkedin && (
                                      <Button
                                        color="default"
                                        href={this.state.faculty.linkedin}
                                        onClick={(e) => e.preventDefault()}
                                        size="small"
                                      >
                                        <i className="fab fa-linkedin"></i>
                                      </Button>
                                    )}
                                    {this.state.faculty.facebook && (
                                      <Button
                                        color="default"
                                        href={this.state.faculty.facebook}
                                        onClick={(e) => e.preventDefault()}
                                        size="small"
                                      >
                                        <i className="fab fa-facebook"></i>
                                      </Button>
                                    )}
                                    {this.state.faculty.twitter && (
                                      <Button
                                        color="default"
                                        href={this.state.faculty.twitter}
                                        onClick={(e) => e.preventDefault()}
                                        size="small"
                                      >
                                        <i className="fab fa-twitter"></i>
                                      </Button>
                                    )} */}
                                  {/* </div> */}
                                </Col>
                                <Col className="order-lg-1" lg="4"></Col>
                              </Row>
                              <div
                                className="text-center mt-8"
                                style={{ paddingTop: "10px" }}
                              >
                                <h3>{this.state.faculty.name}</h3>
                                {/* <div className="h6 font-weight-300">
                                  <i className="ni location_pin mr-2" />
                                  {this.state.faculty.role} Faculty
                                </div> */}
                                <div className="h6 mt-4">
                                  <i className="ni business_briefcase-24 mr-2" />
                                  {/*
                                  <a href={"tel:" + this.state.faculty.phonenumber}>
                                    {this.state.faculty.phonenumber}
                                  </a>{" "}
                                  */}
                                  <br />
                                  {this.state.faculty.mail}
                                  <br />
                                  {this.state.faculty.description}
                                  <br />
                                  {this.state.faculty.department}
                                </div>
                                <div>
                                  <i className="ni education_hat mr-2" />
                                  Indian Institute of Information Technology, Kota
                                </div>
                                <div>
                                  <a href={this.state.faculty.gspl} target="_blank" rel="noopener noreferrer">
                                    Scholar Profile{" "}
                                  </a>
                                </div>
                              </div>
                              <div className="mt-5 py-5 border-top">
                                <Row className="justify-content-center text-justify">
                                  <Col lg="9">
                                    <h5>
                                      <b>Office Address</b>
                                    </h5>
                                    <h6>
                                      Indian Institute of Information Technology, Kota{" "}
                                      <br />
                                      SPL-269, RIICO Industrial Area, Kuber Extension, Ranpur, 
                                      {/* 2nd Floor, East Wing, Prabha Bhawan, MNIT Jaipur
                                      Campus */}
                                      <br /> Kota – 325003, (Rajasthan, India)
                                    </h6>
                                  </Col>
                                  {this.state.faculty.qualification &&
                                    this.state.faculty.qualification.length > 0 && (
                                      <Col lg={9} className="mt-3">
                                        <h5>
                                          <b>Qualification</b>
                                        </h5>
            
                                        <h6>
                                          <ul>
                                            {this.state.faculty.qualification.map(
                                              (qual) => {
                                                return <li>{qual}</li>;
                                              }
                                            )}
                                          </ul>
                                        </h6>
                                      </Col>
                                    )}
                                  {this.state.faculty.tnrexperience &&
                                    this.state.faculty.tnrexperience.length > 0 && (
                                      <Col lg={9} className="mt-3">
                                        <h5>
                                          <b>Teaching & Research Experience</b>
                                        </h5>
            
                                        <h6>
                                          <ul>
                                            {this.state.faculty.tnrexperience.map(
                                              (trnr) => {
                                                return <li>{trnr}</li>;
                                              }
                                            )}
                                          </ul>
                                        </h6>
                                      </Col>
                                    )}
            
                                  {this.state.faculty.research_intersts &&
                                    this.state.faculty.research_intersts.length > 0 && (
                                      <Col lg={9} className="mt-3">
                                        <h5>
                                          <b>Research Interests</b>
                                        </h5>
            
                                        <h6>
                                          <ul>
                                            {this.state.faculty.research_intersts.map(
                                              (ri) => {
                                                return <li>{ri}</li>;
                                              }
                                            )}
                                          </ul>
                                        </h6>
                                      </Col>
                                    )}
                                  {this.state.faculty.rolesnresp &&
                                    this.state.faculty.rolesnresp.length > 0 && (
                                      <Col lg={9} className="mt-3">
                                        {this.state.faculty.rolesnresp &&
                                          this.state.faculty.rolesnresp.length > 0 && (
                                            <h5>
                                              <b>Roles and Responsibilities</b>
                                            </h5>
                                          )}
                                        <h6>
                                          <ul>
                                            {this.state.faculty.rolesnresp &&
                                              this.state.faculty.rolesnresp.map((ri) => {
                                                return <li>{ri}</li>;
                                              })}
                                          </ul>
                                        </h6>
                                      </Col>
                                    )}
            
                                  {this.state.faculty.fundedprojects &&
                                    this.state.faculty.fundedprojects.length > 0 && (
                                      <Col lg={9} className="mt-3">
                                        <h5>
                                          <b>Funded Projects</b>
                                        </h5>
                                        <h6>
                                          <ol>
                                            {this.state.faculty.fundedprojects.map((p) => {
                                              return (
                                                <li>
                                                  &nbsp;{p[0]}
                                                  <ul>
                                                    {/* <li>{p[0]}</li> */}
                                                    <li>
                                                      <b>Role:</b> {p[1]}
                                                    </li>
                                                    <li>
                                                      <b>Amount:</b> {p[2]}
                                                    </li>
                                                    <li>
                                                      <b>Funded By:</b> {p[3]}
                                                    </li>
                                                    <li>
                                                      <b>Status:</b> {p[4]}
                                                    </li>
                                                  </ul>
                                                  <br />
                                                </li>
                                              );
                                            })}
                                          </ol>
                                        </h6>
                                      </Col>
                                    )}
            
                                  {this.state.faculty.outreach_activities &&
                                    this.state.faculty.outreach_activities.length > 0 && (
                                      <Col lg={9} className="mt-3">
                                        <h5>
                                          <b>Outreach Activities</b>
                                        </h5>
                                        <h6>
                                          <ul>
                                            {this.state.faculty.outreach_activities.map(
                                              (ri) => {
                                                return <li>{ri}</li>;
                                              }
                                            )}
                                          </ul>
                                        </h6>
                                      </Col>
                                    )}
            
                                  {this.state.faculty.other &&
                                    this.state.faculty.other.length > 0 && (
                                      <Col lg={9} className="mt-3">
                                        <h5>
                                          <b>Other Notable Information</b>
                                        </h5>
                                        <h6>
                                          <ul>
                                            {this.state.faculty.other.map((ri) => {
                                              return <li>{ri}</li>;
                                            })}
                                          </ul>
                                        </h6>
                                      </Col>
                                    )}
            
                                  <Col lg={9} className="mt-3">
                                    {(this.state.faculty.journals ||
                                      this.state.faculty.conferences) && (
                                      <h5>
                                        <b>Publications</b>
                                      </h5>
                                    )}
                                    <h6>
                                      {this.state.faculty.journals && <b>Journals</b>}
                                      <ol className="mt-2">
                                        {this.state.faculty.journals &&
                                          this.state.faculty.journals.map((ri) => {
                                            return <li>{ri}</li>;
                                          })}
                                      </ol>
                                    </h6>
                                    <h6>
                                      {this.state.faculty.bookchapters && (
                                        <b>Book Chapters</b>
                                      )}
                                      <ol className="mt-2">
                                        {this.state.faculty.bookchapters &&
                                          this.state.faculty.bookchapters.map((ri) => {
                                            return <li>{ri}</li>;
                                          })}
                                      </ol>
                                    </h6>
                                    <h6>
                                      {this.state.faculty.books && <b>Books</b>}
                                      <ol className="mt-2">
                                        {this.state.faculty.books &&
                                          this.state.faculty.books.map((ri) => {
                                            return <li>{ri}</li>;
                                          })}
                                      </ol>
                                    </h6>
                                    <h6>
                                      {this.state.faculty.conferences && <b>Conferences</b>}
                                      <ol className="mt-2">
                                        {this.state.faculty.conferences &&
                                          this.state.faculty.conferences.map((ri) => {
                                            return <li>{ri}</li>;
                                          })}
                                      </ol>
                                    </h6>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Card>
                            
                    }
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default FacultyPage;
